import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react"

interface NavContextType {
  isMobile: boolean
  navColor: string
  navPosition: string
  currentPath: string
  currentContactFormDetails: Object
  absoluteNav: String[]
  changeNavPosition: (position: string) => void
  changeNavColor: (color: string) => void
  changeCurrentPath: (path: string) => void
  changeCurrentContactFormDetails: (values: Object) => void
}
const defaultState = {
  isMobile: false,
  navColor: "black",
  navPosition: "fixed",
  currentPath: "",
  currentContactFormDetails: {},
  absoluteNav: ["/"],
  changeNavColor: (_color: string) => { },
  changeNavPosition: (_position: string) => { },
  changeCurrentPath: (_path: string) => { },
  changeCurrentContactFormDetails: (_values: Object) => { },
}

const NavContext = createContext<NavContextType>(defaultState)

const Provider = ({ children }) => {
  const [navColor, setNavColor] = useState("black")
  const [navPosition, setNavPosition] = useState("fixed")
  const [currentPath, setCurrentPath] = useState("")
  const [currentContactFormDetails, setCurrentContactFormDetails] = useState({})
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const absoluteNav = ["/"]

  const changeIsMobile = () => {
    setIsMobile(window.innerWidth < 768)
  }

  useEffect(() => {
    window.addEventListener("resize", changeIsMobile, { passive: true })
    setIsMobile(window.innerWidth < 768)
    return () => {
      window.removeEventListener("resize", changeIsMobile)
    }
  }, [setIsMobile])

  return (
    <NavContext.Provider
      value={{
        isMobile,
        navColor,
        navPosition,
        currentPath,
        currentContactFormDetails,
        absoluteNav,
        changeNavColor: setNavColor,
        changeNavPosition: setNavPosition,
        changeCurrentPath: setCurrentPath,
        changeCurrentContactFormDetails: setCurrentContactFormDetails,
      }}
    >
      {children}
    </NavContext.Provider>
  )
}
export const useContextValues = () => {
  const {
    isMobile,
    navColor,
    navPosition,
    currentPath,
    currentContactFormDetails,
    absoluteNav,
    changeNavColor,
    changeNavPosition,
    changeCurrentPath,
    changeCurrentContactFormDetails,
  } = useContext(NavContext)
  return {
    isMobile,
    navColor,
    navPosition,
    currentPath,
    currentContactFormDetails,
    absoluteNav,
    changeNavColor,
    changeNavPosition,
    changeCurrentPath,
    changeCurrentContactFormDetails,
  }
}

export { Provider }
