exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-case-study-tsx": () => import("./../../../src/pages/caseStudy.tsx" /* webpackChunkName: "component---src-pages-case-study-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-elearning-development-thankyou-tsx": () => import("./../../../src/pages/elearning-development-thankyou.tsx" /* webpackChunkName: "component---src-pages-elearning-development-thankyou-tsx" */),
  "component---src-pages-hire-flutter-developers-thankyou-tsx": () => import("./../../../src/pages/hire-flutter-developers-thankyou.tsx" /* webpackChunkName: "component---src-pages-hire-flutter-developers-thankyou-tsx" */),
  "component---src-pages-hire-react-developers-india-tsx": () => import("./../../../src/pages/hire-react-developers-india.tsx" /* webpackChunkName: "component---src-pages-hire-react-developers-india-tsx" */),
  "component---src-pages-hire-react-developers-thankyou-tsx": () => import("./../../../src/pages/hire-react-developers-thankyou.tsx" /* webpackChunkName: "component---src-pages-hire-react-developers-thankyou-tsx" */),
  "component---src-pages-hire-react-developers-usa-tsx": () => import("./../../../src/pages/hire-react-developers-usa.tsx" /* webpackChunkName: "component---src-pages-hire-react-developers-usa-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-elearning-development-tsx": () => import("./../../../src/pages/landing/elearning-development.tsx" /* webpackChunkName: "component---src-pages-landing-elearning-development-tsx" */),
  "component---src-pages-landing-hire-flutter-developers-tsx": () => import("./../../../src/pages/landing/hire-flutter-developers.tsx" /* webpackChunkName: "component---src-pages-landing-hire-flutter-developers-tsx" */),
  "component---src-pages-landing-hire-react-developers-tsx": () => import("./../../../src/pages/landing/hire-react-developers.tsx" /* webpackChunkName: "component---src-pages-landing-hire-react-developers-tsx" */),
  "component---src-pages-landing-lms-development-company-tsx": () => import("./../../../src/pages/landing/LMS-development-company.tsx" /* webpackChunkName: "component---src-pages-landing-lms-development-company-tsx" */),
  "component---src-pages-landing-mvp-development-company-tsx": () => import("./../../../src/pages/landing/MVP-development-company.tsx" /* webpackChunkName: "component---src-pages-landing-mvp-development-company-tsx" */),
  "component---src-pages-landing-mvp-development-tsx": () => import("./../../../src/pages/landing/mvp-development.tsx" /* webpackChunkName: "component---src-pages-landing-mvp-development-tsx" */),
  "component---src-pages-landing-software-developers-india-tsx": () => import("./../../../src/pages/landing/software-developers-india.tsx" /* webpackChunkName: "component---src-pages-landing-software-developers-india-tsx" */),
  "component---src-pages-lms-development-company-thankyou-tsx": () => import("./../../../src/pages/LMS-development-company-thankyou.tsx" /* webpackChunkName: "component---src-pages-lms-development-company-thankyou-tsx" */),
  "component---src-pages-mvp-development-company-thankyou-tsx": () => import("./../../../src/pages/MVP-development-company-thankyou.tsx" /* webpackChunkName: "component---src-pages-mvp-development-company-thankyou-tsx" */),
  "component---src-pages-mvp-development-thankyou-tsx": () => import("./../../../src/pages/mvp-development-thankyou.tsx" /* webpackChunkName: "component---src-pages-mvp-development-thankyou-tsx" */),
  "component---src-pages-openings-tsx": () => import("./../../../src/pages/openings.tsx" /* webpackChunkName: "component---src-pages-openings-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-software-dev-ind-thankyou-tsx": () => import("./../../../src/pages/SoftwareDevInd-thankyou.tsx" /* webpackChunkName: "component---src-pages-software-dev-ind-thankyou-tsx" */),
  "component---src-pages-technical-architecture-for-web-app-and-mobile-app-tsx": () => import("./../../../src/pages/technical-architecture-for-web-app-and-mobile-app.tsx" /* webpackChunkName: "component---src-pages-technical-architecture-for-web-app-and-mobile-app-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-thanks-for-inquiry-tsx": () => import("./../../../src/pages/ThanksForInquiry.tsx" /* webpackChunkName: "component---src-pages-thanks-for-inquiry-tsx" */),
  "component---src-pages-thankyou-react-india-tsx": () => import("./../../../src/pages/thankyou-react-india.tsx" /* webpackChunkName: "component---src-pages-thankyou-react-india-tsx" */),
  "component---src-pages-thankyou-react-usa-tsx": () => import("./../../../src/pages/thankyou-react-usa.tsx" /* webpackChunkName: "component---src-pages-thankyou-react-usa-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-pages-thankyou-usaind-tsx": () => import("./../../../src/pages/thankyou-usaind.tsx" /* webpackChunkName: "component---src-pages-thankyou-usaind-tsx" */),
  "component---src-pages-web-design-company-tsx": () => import("./../../../src/pages/web-design-company.tsx" /* webpackChunkName: "component---src-pages-web-design-company-tsx" */),
  "component---src-pages-web-developer-india-thankyou-tsx": () => import("./../../../src/pages/web-developer-india-thankyou.tsx" /* webpackChunkName: "component---src-pages-web-developer-india-thankyou-tsx" */),
  "component---src-pages-web-developers-india-tsx": () => import("./../../../src/pages/web-developers-india.tsx" /* webpackChunkName: "component---src-pages-web-developers-india-tsx" */),
  "component---src-pages-website-design-and-development-company-india-tsx": () => import("./../../../src/pages/website-design-and-development-company-india.tsx" /* webpackChunkName: "component---src-pages-website-design-and-development-company-india-tsx" */),
  "component---src-templates-edtech-tsx": () => import("./../../../src/templates/edtech.tsx" /* webpackChunkName: "component---src-templates-edtech-tsx" */),
  "component---src-templates-hiring-tsx": () => import("./../../../src/templates/hiring.tsx" /* webpackChunkName: "component---src-templates-hiring-tsx" */),
  "component---src-templates-portfolio-details-tsx": () => import("./../../../src/templates/portfolioDetails.tsx" /* webpackChunkName: "component---src-templates-portfolio-details-tsx" */),
  "component---src-templates-pseo-web-developer-tsx": () => import("./../../../src/templates/pseoWebDeveloper.tsx" /* webpackChunkName: "component---src-templates-pseo-web-developer-tsx" */),
  "component---src-templates-pseo-web-development-company-tsx": () => import("./../../../src/templates/pseoWebDevelopmentCompany.tsx" /* webpackChunkName: "component---src-templates-pseo-web-development-company-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-templates-technology-new-tsx": () => import("./../../../src/templates/technologyNew.tsx" /* webpackChunkName: "component---src-templates-technology-new-tsx" */)
}

